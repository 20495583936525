:root {
  --PlayerOverlay-animationDuration: 0.5s;
  --PlayerOverlay-iconDuration: 0.3s;
}
._1AkIN8g0cJ5-iU_vHoQx6U {
  z-index: 1000;
  position: absolute;
  width: 100%;
  height: 100%;
  background: var(--color-player-overlay);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

._1ZjUg47WriuHdRme8qlJet {
  position: absolute;
  left: 44px;
  top: 30px;
  right: 50px;
  color: var(--color-player-default1);
  display: flex;
  align-items: center;
  height: 60px;
  width: max-content;
}

._1gre-QOgtiqujVWutvG-rk {
  width: 24px;
  height: 32px;
  transform: rotate(180deg);
  stroke: var(--color-player-default1);
  stroke-width: 2px;
  cursor: pointer;
  transition: stroke var(--PlayerOverlay-iconDuration) ease-in-out;
}

._1ZjUg47WriuHdRme8qlJet:hover {
  color: var(--color-player-highlight-hover1);
  cursor: pointer;
}

._1ZjUg47WriuHdRme8qlJet:hover svg {
  stroke: var(--color-player-highlight-hover1);
}

/* .headerBack:hover {
  stroke: var(--color-player-highlight-hover1);
} */

._2Vzs7czIw-N1_eWomuhtzL {
  width: 80px;
  height: 80px;
  display: inline-block;
  margin-left: 15px;
}

._3ODxsqVMMLkeYDMDzzo1RC {
  display: inline-block;
  margin-left: 20px;
  font-size: var(--fontsize-l);
}

/* .headerTexts:hover {
  color: var(--color-player-highlight-hover1);
  cursor: pointer;
} */

._3J9dfbF5gjXox8Ao-6ev8E {
  font-weight: bold;
  margin: 0;
}

._2TcyF78PMDwpmBOMDWHdMV {
  margin: 0;
}

._3J9dfbF5gjXox8Ao-6ev8E + ._2TcyF78PMDwpmBOMDWHdMV {
  margin-top: 10px;
}

._3AZttNVNbECxVLCwuzW5Jq {
  justify-content: center;
  display: flex;
  align-items: center;
}

._1cRIti1Z89VySxE6dXCdCn {
  display: inline-block;
}

._3xwHVlYBr_R-tPsvjUXVLQ > svg {
  cursor: pointer;
  margin: 0 auto;
  z-index: 9999;
  width: 100px;
  height: 100px;
  fill: var(--color-icon-playlock-fill);
  stroke: var(--color-icon-playlock-stroke);
  transition: fill var(--PlayerOverlay-animationDuration) ease-in-out,
    stroke var(--PlayerOverlay-animationDuration) ease-in-out;
}

._3xwHVlYBr_R-tPsvjUXVLQ circle {
  fill: var(--color-icon-playlock-circle-fill);
  fill-opacity: var(--color-icon-playlock-circle-fillOpacity);
  stroke-width: var(--color-icon-playlock-circle-strokeWidth);
}

._3xwHVlYBr_R-tPsvjUXVLQ:hover {
  fill: var(--color-icon-playlock-fill-hover);
  stroke: var(--color-icon-playlock-stroke-hover);
}

._1rn3ywfKX0EQMoXk3pBVXg {
  width: 60px;
  height: 60px;
  margin: 100px;
  cursor: pointer;
  display: inline-block;
  z-index: 10000;
  color: var(--color-player-default1);
  fill: var(--color-player-default1);
  stroke: var(--color-player-default1);
  stroke-width: 0.4;
  transition: fill var(--PlayerOverlay-animationDuration) ease-in-out,
    stroke var(--PlayerOverlay-animationDuration) ease-in-out;
}
._1rn3ywfKX0EQMoXk3pBVXg:hover {
  fill: var(--color-player-highlight-hover1);
  stroke: var(--color-player-highlight-hover1);
}

._2KWmhonmwWcfpUtPR56TOt {
  background: var(--color-content-progressbar-buffer-empty2);
  position: absolute;
  width: calc(100% - 100px);
  bottom: 100px;
  left: 50px;
  right: 50px;
  height: 6px;
  border-radius: 6px;
}

._2WvenNEB27O0HZH6sCWNLz {
  position: absolute;
  right: 50px;
  top: calc(50vh - 20px);
  height: 40px;
  width: 40px;
  cursor: pointer;
  fill: var(--color-player-default1);
  transition: fill var(--PlayerOverlay-iconDuration) ease-in-out;
}

._2WvenNEB27O0HZH6sCWNLz:hover {
  fill: var(--color-player-highlight-hover1);
}

._7ybVe-1ttKVgwgLv49cOD,
._7ybVe-1ttKVgwgLv49cOD:hover {
  fill: var(--color-player-default1);
  opacity: 0.5;
  cursor: not-allowed;
}

._2QdU6IQuK2DSxmpxWMuyuZ {
  position: absolute;
  bottom: 107px;
  color: var(--color-player-default1);
  right: 50px;
}
._3y9T5gAM_dFoC86k5f4_et {
  top: var(--EPGGridText-textContainer-padding);
  right: var(--EPGGridText-textContainer-padding);
  fill: var(--color-primary1);
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.QzNDHAqkleTBVX7T7PWF {
  color: var(--color-player-default1);
  border: none;
  background: transparent;
  position: absolute;
  bottom: 30px;
  right: 50px;
  display: flex;
}
._26JSjogSanMKUgZwuyb6e3 {
  color: var(--color-player-default1);
  border: none;
  background: transparent;
  position: absolute;
  bottom: 30px;
  left: 50px;
  display: flex;
}

.QzNDHAqkleTBVX7T7PWF > * {
  margin: 0 20px;
}

._1BCjNBxPlLm7SvoBVtnXhE {
  cursor: pointer;
  fill: var(--color-player-default1);
  stroke: var(--color-player-default1);
  transition: fill var(--PlayerOverlay-iconDuration) ease-in-out,
    stroke var(--PlayerOverlay-iconDuration) ease-in-out;
}

._1BCjNBxPlLm7SvoBVtnXhE:hover {
  fill: var(--color-player-highlight-hover1);
  stroke: var(--color-player-highlight-hover1);
}

._2w-vyLjnkEc8ruxt8VuUsw {
  position: absolute;
  bottom: 200px;
  right: 40px;
  width: 400px;
  height: 110px;
  background: var(--color-content-overlay1);
  z-index: 2000;
  padding: 10px;
  box-sizing: border-box;
}

.PKJQaFZMxw2FswB2AeYui {
  height: 90px;
  width: 160px;
  margin-right: 10px;
  display: inline-block;
}

._3P5ulCvE_UfgyTbRKx6KrP {
  width: 210px;
  height: 90px;
  position: absolute;
  display: inline-flex;
  right: 10px;
  align-items: center;
}

._2ds3xLywiAgjTPxjmtnB2D {
  width: 174px;
}

._3C3LjtrZmdpBvFo1zEth2z {
  font-size: var(--fontSize-s);
  color: var(--color-player-default2);
  margin-bottom: 5px;
}
.DBjPlm_WwfAB1ysFfSOlX {
  margin-top: 5px;
  font-size: var(--fontSize-m);
  font-weight: bold;
  color: var(--color-player-default1);
  max-width: 180px;
  text-overflow: ellipsis;
  /* hide text if it more than N lines  */
  overflow: hidden;
  /* for set '...' in absolute position */
  position: relative;
  /* use this value to count block height */
  line-height: 1.2em;
  /* max-height = line-height (1.2) * lines max number (3) */
  max-height: 3.6em;
}

/* hide ... if we have text, which is less than or equal to max lines */
.nixUfjL-XqoP85ZJ8aq1Q:after {
  /* points in the end */
  content: "";
  /* absolute position */
  position: absolute;
  /* set position to right bottom corner of text */
  right: 0;
  /* set width and height */
  width: 1em;
  height: 1em;
  margin-top: 0.2em;
  /* bg color = bg color under block */
  background: white;
}

._1qaqDMPY7azYaFfQZ4Je03 {
  position: absolute;
  right: 5px;
  top: 20px;
  width: 24px;
  height: 24px;
  fill: var(--color-player-default1);
  cursor: pointer;
  transition: fill var(--PlayerOverlay-iconDuration) ease-in-out;
}

._1qaqDMPY7azYaFfQZ4Je03:hover {
  fill: var(--color-player-highlight-hover1);
}

._3FAOHKua_QJfX-VRbjbz-d {
  position: absolute;
  top: 20px;
  right: 100px;
  width: 200px;
  background: white;
  color: black;
  z-index: 1001;
}

/* Overlay aninmation */
._2tI4fj5_jDK3RG24DNWqvt {
  opacity: 0;
}

._2x4x3POtGJwIhXCTWMLx_B {
  opacity: 0;
}

._3VMXlwnKUElViNRQ-4ElCo {
  transition: opacity var(--PlayerOverlay-animationDuration) ease-in-out;
  opacity: 1;
}

._1MXGgSe5pwPk8zMVsQAxbE {
  opacity: 1;
}

._14xwVGwahtmuQiKBb3pRSW {
  opacity: 1;
}

._3r9-9kCt5KZpuKT7ww-XUv {
  opacity: 0;
  transition: opacity var(--PlayerOverlay-animationDuration) ease-in-out;
}

._1X1ZaY9q6jsPqsFSoFoxnl {
  opacity: 0;
  visibility: hidden;
  display: none;
}

._3Gx6k-GaZKUJRHr5jm2e0h,
._21R_a1_NPdtTyC3StMF5Oe {
  position: absolute;
  bottom: 90px;
  right: 50px;
  font-weight: normal;
  text-transform: inherit;
  width: auto;
  display: block;
  margin: 0;
  border-radius: 5px;
  background-color: white;
  color: black;
}

._21R_a1_NPdtTyC3StMF5Oe {
  bottom: 155px;
}

._3Gx6k-GaZKUJRHr5jm2e0h:hover,
._21R_a1_NPdtTyC3StMF5Oe:hover {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border-color: white;
}

._3Gx6k-GaZKUJRHr5jm2e0h .JspUcFl0CyRrNHFE1m801 > svg,
._21R_a1_NPdtTyC3StMF5Oe .JspUcFl0CyRrNHFE1m801 > svg {
  width: 10px;
  height: 10px;
  fill: black;
}

._3Gx6k-GaZKUJRHr5jm2e0h:hover .JspUcFl0CyRrNHFE1m801 > svg,
skipIntroButton:hover .JspUcFl0CyRrNHFE1m801 > svg {
  fill: white;
}

@media (max-width: 1023px) {
  ._1rn3ywfKX0EQMoXk3pBVXg {
    margin: 50px;
    height: 50px;
    width: 50px;
  }

  ._3xwHVlYBr_R-tPsvjUXVLQ > svg {
    height: 80px;
    width: 80px;
  }

  ._3Gx6k-GaZKUJRHr5jm2e0h {
    bottom: 125px;
  }

  ._21R_a1_NPdtTyC3StMF5Oe {
    bottom: 160px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  ._2QdU6IQuK2DSxmpxWMuyuZ {
    bottom: 160px;
  }

  ._2KWmhonmwWcfpUtPR56TOt {
    bottom: 110px !important;
  }

  .QzNDHAqkleTBVX7T7PWF,
  ._26JSjogSanMKUgZwuyb6e3 {
    bottom: 80px;
  }
}

@media (max-width: 767px) {
  ._1AkIN8g0cJ5-iU_vHoQx6U {
    height: 95%;
  }
  ._1ZjUg47WriuHdRme8qlJet {
    left: 15px;
    top: 5px;
    display: inline-flex;
  }
  ._2Vzs7czIw-N1_eWomuhtzL {
    margin-left: 10px;
  }

  ._1gre-QOgtiqujVWutvG-rk {
    height: 20px;
    width: 13px;
  }

  ._3ODxsqVMMLkeYDMDzzo1RC {
    margin-left: 10px;
  }

  ._3J9dfbF5gjXox8Ao-6ev8E {
    font-size: var(--fontSize-s);
  }

  ._2TcyF78PMDwpmBOMDWHdMV,
  ._1JUat_ow8z6Mcw2m-4U-mw {
    font-size: var(--fontSize-xs);
  }

  ._2TcyF78PMDwpmBOMDWHdMV {
    margin: 0;
  }

  ._1rn3ywfKX0EQMoXk3pBVXg {
    margin: 20px;
    height: 40px;
    width: 40px;
  }

  ._3xwHVlYBr_R-tPsvjUXVLQ > svg {
    height: 60px;
    width: 60px;
  }

  ._2WvenNEB27O0HZH6sCWNLz {
    height: 25px;
    width: 25px;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  ._2QdU6IQuK2DSxmpxWMuyuZ {
    font-size: var(--fontSize-xs);
  }

  ._2KWmhonmwWcfpUtPR56TOt {
    width: calc(100% - 20px);
    left: 10px;
    right: 10px;
  }

  ._2w-vyLjnkEc8ruxt8VuUsw {
    width: 340px;
    height: 72px;
    bottom: 20%;
    left: 10px;
  }

  .PKJQaFZMxw2FswB2AeYui {
    width: 92px;
    height: 52px;
    margin-right: 5px;
  }

  ._3P5ulCvE_UfgyTbRKx6KrP {
    width: 208px;
    height: 52px;
    right: 18px;
  }

  .DBjPlm_WwfAB1ysFfSOlX {
    font-size: var(--fontSize-s);
    white-space: nowrap;
  }

  ._3C3LjtrZmdpBvFo1zEth2z {
    font-size: var(--fontSize-xs);
  }

  ._1qaqDMPY7azYaFfQZ4Je03 {
    top: 13px;
    right: 0;
  }

  .QzNDHAqkleTBVX7T7PWF {
    right: 10px;
  }

  .QzNDHAqkleTBVX7T7PWF > * {
    margin: 0 10px;
  }

  ._3Gx6k-GaZKUJRHr5jm2e0h {
    bottom: 90px;
    right: 20px;
  }

  ._21R_a1_NPdtTyC3StMF5Oe {
    bottom: 150px;
    right: 20px;
  }

  ._2QdU6IQuK2DSxmpxWMuyuZ {
    right: 20px;
  }
}

@media (max-width: 359px) {
  ._2w-vyLjnkEc8ruxt8VuUsw {
    bottom: 15%;
  }
}
@media only screen and (max-height: 767px) and (orientation: landscape) {
  ._1AkIN8g0cJ5-iU_vHoQx6U {
    height: 100%;
    overflow: hidden;
  }
  ._2QdU6IQuK2DSxmpxWMuyuZ {
    bottom: 115px;
  }
  ._2KWmhonmwWcfpUtPR56TOt {
    bottom: 100px;
  }
  .QzNDHAqkleTBVX7T7PWF {
    bottom: 65px;
  }
  ._2WvenNEB27O0HZH6sCWNLz {
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
